import { initializeApp, getApps, getApp } from 'firebase/app';
import { 
  getAuth, 
  setPersistence, 
  browserLocalPersistence, 
  browserSessionPersistence,
  signInWithCustomToken, 
  signOut, 
  onAuthStateChanged 
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

class FirebaseSingleton {
  constructor() {
    this.app = null;
    this.auth = null;
    this.firestore = null;
    this.initializationPromise = null;
    this.initializationAttempts = 0;
    this.maxInitializationAttempts = 3;
  }

  async initialize(firebaseConfig, options = {}) {
    if (this.app) {
      console.log('[FirebaseSingleton] Firebase already initialized');
      return this.app;
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      try {
        if (this.initializationAttempts >= this.maxInitializationAttempts) {
          throw new Error('Maximum initialization attempts reached');
        }
        this.initializationAttempts++;

        console.log('[FirebaseSingleton] Starting Firebase initialization...');

        const existingApps = getApps();
        if (existingApps.length > 0) {
          console.log('[FirebaseSingleton] Found existing Firebase app');
          this.app = getApp();
        } else {
          console.log('[FirebaseSingleton] Initializing new Firebase app');
          this.app = initializeApp(firebaseConfig);
        }

        this.auth = getAuth(this.app);
        const persistenceType = options.persistence || 
          (localStorage.getItem('cookieConsent') === 'true' ? 
            browserLocalPersistence : 
            browserSessionPersistence);
        
        await setPersistence(this.auth, persistenceType);
        console.log(`[FirebaseSingleton] Auth persistence set to: ${persistenceType === browserLocalPersistence ? 'LOCAL' : 'SESSION'}`);

        this.firestore = getFirestore(this.app);
        console.log('[FirebaseSingleton] Firestore initialized');

        this.initializationAttempts = 0;
        console.log('[FirebaseSingleton] Firebase initialization complete');
        
        return this.app;
      } catch (error) {
        console.error('[FirebaseSingleton] Initialization error:', error);
        
        this.resetInitialization();
        
        if (this.initializationAttempts < this.maxInitializationAttempts) {
          console.log(`[FirebaseSingleton] Retrying initialization (Attempt ${this.initializationAttempts + 1}/${this.maxInitializationAttempts})`);
          return this.initialize(firebaseConfig, options);
        }
        
        throw new Error(`Firebase initialization failed after ${this.maxInitializationAttempts} attempts: ${error.message}`);
      } finally {
        if (!this.app) {
          this.initializationPromise = null;
        }
      }
    })();

    return this.initializationPromise;
  }

  resetInitialization() {
    console.log('[FirebaseSingleton] Resetting Firebase initialization');
    try {
      if (this.auth) {
        signOut(this.auth).catch(() => {});
      }
    } catch (e) {
      console.warn('[FirebaseSingleton] Error during signout on reset:', e);
    }
    this.app = null;
    this.auth = null;
    this.firestore = null;
    this.initializationPromise = null;
  }

  isInitialized() {
    return !!(this.app && this.auth && this.firestore);
  }

  getApp() {
    if (!this.app) {
      throw new Error('[FirebaseSingleton] Firebase app not initialized');
    }
    return this.app;
  }

  getAuthInstance() {
    if (!this.auth) {
      throw new Error('[FirebaseSingleton] Auth not initialized');
    }
    return this.auth;
  }

  getFirestoreInstance() {
    if (!this.firestore) {
      throw new Error('[FirebaseSingleton] Firestore not initialized');
    }
    return this.firestore;
  }

  async signInWithCustomToken(token) {
    try {
      if (!token) {
        throw new Error('Token is required');
      }
      
      console.log('[FirebaseSingleton] Attempting custom token sign in');
      const auth = this.getAuthInstance();
      const userCredential = await signInWithCustomToken(auth, token);
      console.log('[FirebaseSingleton] Custom token sign in successful');
      return userCredential;
    } catch (error) {
      console.error('[FirebaseSingleton] Custom token sign in failed:', error);
      throw error;
    }
  }

  async signOut() {
    try {
      console.log('[FirebaseSingleton] Signing out');
      const auth = this.getAuthInstance();
      await signOut(auth);
      console.log('[FirebaseSingleton] Sign out successful');
    } catch (error) {
      console.error('[FirebaseSingleton] Sign out failed:', error);
      throw error;
    }
  }

  onAuthStateChanged(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }

    try {
      const auth = this.getAuthInstance();
      return onAuthStateChanged(auth, (user) => {
        console.log(`[FirebaseSingleton] Auth state changed: ${user ? 'User signed in' : 'User signed out'}`);
        callback(user);
      }, (error) => {
        console.error('[FirebaseSingleton] Auth state change error:', error);
        callback(null, error);
      });
    } catch (error) {
      console.error('[FirebaseSingleton] Auth state listener setup failed:', error);
      throw error;
    }
  }

  async getCurrentUser() {
    try {
      const auth = this.getAuthInstance();
      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe();
            resolve(user);
          },
          (error) => {
            unsubscribe();
            reject(error);
          }
        );
      });
    } catch (error) {
      console.error('[FirebaseSingleton] Get current user failed:', error);
      throw error;
    }
  }
}

const firebaseInstance = new FirebaseSingleton();
export default firebaseInstance;
