import React, { createContext, useState, useContext, useCallback } from 'react';

const GuestContext = createContext();

export const useGuest = () => useContext(GuestContext);

export const GuestProvider = ({ children }) => {
  const [isGuest, setIsGuest] = useState(false);
  const [guestData, setGuestData] = useState(null);

  // Function to enable guest mode and optionally set guest-specific data
  const setGuestMode = useCallback((data = null) => {
    setIsGuest(true);
    if (data) {
      setGuestData(data);
    }
  }, []);

  // Function to exit guest mode and clear guest data if any
  const clearGuestMode = useCallback(() => {
    setIsGuest(false);
    setGuestData(null);
  }, []);

  return (
    <GuestContext.Provider value={{ isGuest, guestData, setGuestMode, clearGuestMode }}>
      {children}
    </GuestContext.Provider>
  );
};

export default GuestProvider;
