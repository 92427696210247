// api.js

import axios from 'axios';
import { corsHandler } from '../config/corsConfig';
import {
  API_BASE_URL,
  AUTH_ENDPOINTS,
  AUTH_CONFIG,
  REQUEST_CONFIG,
  ERROR_MESSAGES,
  SUPPORTED_PLATFORMS,
  ENV_CONFIG,
  PLATFORM_CONFIG,
} from '../config/authConfig';

// Token management
let authToken = null;

const setAuthToken = (token) => {
  if (token) {
    authToken = token;
    localStorage.setItem(AUTH_CONFIG.tokenStorageKey, token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    removeAuthToken();
  }
};

const getAuthToken = () => authToken;

const removeAuthToken = () => {
  authToken = null;
  localStorage.removeItem(AUTH_CONFIG.tokenStorageKey);
  localStorage.removeItem(AUTH_CONFIG.refreshTokenStorageKey);
  delete api.defaults.headers.common['Authorization'];
};

// Create base API instance
const api = axios.create({
  baseURL: API_BASE_URL,
  ...REQUEST_CONFIG,
  timeout: REQUEST_CONFIG.timeout,
  validateStatus: (status) => status >= 200 && status < 400, // Include 3xx statuses
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    config.headers = {
      ...config.headers,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
    };
    if (!ENV_CONFIG.isProduction) {
      console.log('[API] Making request:', {
        url: config.url,
        method: config.method,
        data: config.data,
      });
    }
    return config;
  },
  (error) => {
    console.error('[API] Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Don't check content-type for 302 responses
    if (response.status === 302) {
      return response;
    }

    // Only check content-type for non-redirect responses
    const contentType = response.headers['content-type'] || '';
    if (!response.headers?.location && contentType.includes('text/html')) {
      throw new Error(ERROR_MESSAGES.invalidResponse);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem(AUTH_CONFIG.refreshTokenStorageKey);
        const res = await corsHandler(() =>
          axios.post(`${API_BASE_URL}${AUTH_ENDPOINTS.refreshToken}`, { refreshToken })
        );
        const { token } = res.data;
        setAuthToken(token);
        return api(originalRequest);
      } catch (refreshError) {
        console.error('[API] Error refreshing token:', refreshError);
        removeAuthToken();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// Utility functions
const retryRequest = async (
  fn,
  retries = AUTH_CONFIG.maxRetries,
  delay = AUTH_CONFIG.retryDelay
) => {
  let lastError;
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      lastError = error;
      if (i < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay * Math.pow(2, i)));
        console.log(`[API] Retry attempt ${i + 1} of ${retries}`);
      }
    }
  }
  throw lastError;
};

// Error handler
const handleApiError = (error) => {
  console.error('[API] Error:', error);

  if (error.response) {
    console.error('[API] Response data:', error.response.data);
    return {
      success: false,
      error: error.response.data.message || error.message,
      statusCode: error.response.status,
      details: error.response.data,
    };
  } else if (error.request) {
    console.error('[API] No response received:', error.request);
    return {
      success: false,
      error: ERROR_MESSAGES.networkError,
      statusCode: 0,
      details: { request: error.request },
    };
  } else {
    console.error('[API] Error setting up request:', error.message);
    return {
      success: false,
      error: error.message,
      statusCode: -1,
      details: { message: error.message },
    };
  }
};

// OAuth handling
const initiateOAuth = async (platform, oauthData = null) => {
  if (!SUPPORTED_PLATFORMS.includes(platform)) {
    return {
      success: false,
      error: ERROR_MESSAGES.invalidPlatform(platform),
    };
  }

  try {
    if (platform === 'telegram') {
      throw new Error('Telegram authentication should use the widget flow');
    }

    const callbackUrl =
      PLATFORM_CONFIG[platform]?.callbackUrl || `${window.location.origin}/auth/callback`;

    const headers = {};
    const token = getAuthToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    let response;
    if (oauthData) {
      response = await retryRequest(() =>
        corsHandler(() =>
          api.post(
            AUTH_ENDPOINTS.oauthCallback,
            {
              platform,
              ...oauthData,
            },
            { headers }
          )
        )
      );
    } else {
      response = await retryRequest(() =>
        corsHandler(() =>
          api.post(
            AUTH_ENDPOINTS.initiateOAuth,
            { platform, redirectUri: callbackUrl },
            { headers }
          )
        )
      );
    }

    if (!response.data) {
      throw new Error(ERROR_MESSAGES.serverError);
    }

    // Handle redirect responses
    if (response.status === 302 && response.headers?.location) {
      return { success: true, data: response.headers.location };
    }

    if (typeof response.data === 'string') {
      return { success: true, data: response.data };
    } else if (response.data.authUrl || response.data.url) {
      return {
        success: true,
        data: response.data.authUrl || response.data.url,
      };
    } else if (response.data.firebaseToken) {
      return {
        success: true,
        data: { firebaseToken: response.data.firebaseToken },
      };
    }

    throw new Error(ERROR_MESSAGES.invalidResponse);
  } catch (error) {
    return {
      success: false,
      error: error.message || ERROR_MESSAGES.serverError,
      details: error.response?.data,
    };
  }
};

// Profile Management
const getUserProfile = async () => {
  try {
    const response = await retryRequest(() =>
      corsHandler(() => api.get(AUTH_ENDPOINTS.getUserProfile))
    );
    return response.data
      ? { success: true, data: response.data }
      : { success: false, error: ERROR_MESSAGES.noData };
  } catch (error) {
    return handleApiError(error);
  }
};

const updateUserProfile = async (profileData) => {
  try {
    const response = await retryRequest(() =>
      corsHandler(() => api.put(AUTH_ENDPOINTS.updateUserProfile, profileData))
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Session Management
const logout = async () => {
  try {
    await corsHandler(() => api.post(AUTH_ENDPOINTS.logout));
    removeAuthToken();
    return { success: true };
  } catch (error) {
    removeAuthToken(); // Always remove tokens on logout
    return handleApiError(error);
  }
};

// OAuth Utilities
const handleOAuthCallback = async (platform, code, state) => {
  try {
    const response = await retryRequest(() =>
      corsHandler(() => api.post(AUTH_ENDPOINTS.oauthCallback, { platform, code, state }))
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

// Export functions
export default api;

export {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  getUserProfile,
  updateUserProfile,
  logout,
  initiateOAuth,
  handleOAuthCallback,
  SUPPORTED_PLATFORMS,
  retryRequest,
};
