// corsConfig.js
import axios from 'axios';

// Get the API base URL from the environment variables
const API_BASE_URL = 'https://us-central1-shitcoins-game.cloudfunctions.net';
console.log('[corsConfig] API_BASE_URL:', API_BASE_URL);

// Create an Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add cache prevention
    config.headers = {
      ...config.headers,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    };

    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    console.log('[corsConfig] Making request to:', `${config.baseURL}${config.url}`);
    return config;
  },
  (error) => {
    console.error('[corsConfig] Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('[corsConfig] Response error:', {
      url: error.config?.url,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

// API wrapper
export const api = {
  get: (url, config = {}) => axiosInstance.get(url, config),
  post: (url, data, config = {}) => axiosInstance.post(url, data, config),
  put: (url, data, config = {}) => axiosInstance.put(url, data, config),
  delete: (url, config = {}) => axiosInstance.delete(url, config)
};

// Error handler
export const corsHandler = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    console.error('[corsHandler] Error:', error);
    throw error;
  }
};

// Token management
export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('authToken');
    delete axiosInstance.defaults.headers['Authorization'];
  }
};

export const getAuthToken = () => localStorage.getItem('authToken');

export default api;