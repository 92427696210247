// auth.jsx

import firebaseInstance from './firebaseSingleton.jsx';
import {
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { setAuthToken } from '../services/api';

const GUEST_USER_KEY = 'guestUser';
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// Retry utility
const withRetry = async (operation, maxRetries = MAX_RETRIES, delay = RETRY_DELAY) => {
  let lastError;

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      console.warn(`[Auth] Attempt ${attempt + 1} failed:`, error);

      if (attempt < maxRetries - 1) {
        await new Promise((resolve) =>
          setTimeout(resolve, delay * Math.pow(2, attempt))
        );
      }
    }
  }

  throw lastError;
};

// Authenticate with Firebase
export async function authenticateWithFirebase(firebaseToken) {
  console.log('[Auth] Attempting to authenticate with Firebase...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    if (!auth) throw new Error('Firebase instance not initialized');

    await setPersistence(auth, browserLocalPersistence);
    const userCredential = await signInWithCustomToken(auth, firebaseToken);
    console.log(
      '[Auth] User authenticated successfully with Firebase:',
      userCredential.user.uid
    );

    const idToken = await userCredential.user.getIdToken();
    setAuthToken(idToken);

    return userCredential.user;
  } catch (error) {
    console.error('[Auth] Error authenticating with Firebase:', error);

    if (
      error.code === 'auth/invalid-custom-token' ||
      error.code === 'auth/token-expired'
    ) {
      console.log('[Auth] Token expired or invalid');
      throw new Error('TOKEN_EXPIRED');
    }

    throw new Error('Firebase authentication failed: ' + error.message);
  }
}

// Set up guest mode
export function setUpGuestMode() {
  console.log('[Auth] Setting up guest mode...');
  const guestUser = {
    uid: 'guest-' + Date.now(),
    isGuest: true,
    isAuthenticated: true,
  };
  localStorage.setItem(GUEST_USER_KEY, JSON.stringify(guestUser));
  return guestUser;
}

// Sign out user
export async function signOutUser() {
  console.log('[Auth] Attempting to sign out user...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    await signOut(auth);
    localStorage.removeItem(GUEST_USER_KEY);
    setAuthToken(null);
    console.log('[Auth] User signed out successfully');
  } catch (error) {
    console.error('[Auth] Error signing out:', error);
    throw new Error('Failed to sign out: ' + error.message);
  }
}

// Refresh auth token
export async function refreshAuthToken() {
  console.log('[Auth] Attempting to refresh auth token...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    const currentUser = auth.currentUser;
    if (currentUser) {
      const newToken = await currentUser.getIdToken(true);
      setAuthToken(newToken);
      console.log('[Auth] Auth token refreshed successfully');
      return newToken;
    } else {
      throw new Error('No authenticated user');
    }
  } catch (error) {
    console.error('[Auth] Error refreshing auth token:', error);
    throw new Error('Failed to refresh auth token: ' + error.message);
  }
}

// Handle Firebase Auth state change
export function onAuthStateChange(callback) {
  const auth = firebaseInstance.getAuthInstance();
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      console.log('[Auth] Auth state changed: User is signed in', user.uid);
      try {
        const idToken = await user.getIdToken();
        setAuthToken(idToken);
      } catch (error) {
        console.error('[Auth] Error getting ID token:', error);
      }
      callback(user);
    } else {
      console.log('[Auth] Auth state changed: User is signed out');
      setAuthToken(null);
      const guestUser = localStorage.getItem(GUEST_USER_KEY);
      if (guestUser) {
        console.log('[Auth] Guest user detected');
        callback(JSON.parse(guestUser));
      } else {
        callback(null);
      }
    }
  });
}

// Check if current user is a guest
export function isGuestUser() {
  const guestUser = localStorage.getItem(GUEST_USER_KEY);
  return !!guestUser;
}

export default {
  authenticateWithFirebase,
  setUpGuestMode,
  signOutUser,
  refreshAuthToken,
  onAuthStateChange,
  isGuestUser,
};
